import {
    ERROR_AGE_GROUPS,
    ERROR_ILLNESSES,
    ERROR_SERVICES,
    FETCH_ERROR,
    SEARCH_SUCCESS,
    SERVICE_START,
    SET_AGE_GROUPS,
    SET_CORE_SERVICES,
    SET_FILTER_TERM_AGGREGATIONS,
    SET_ILLNESSES,
    SET_SERVICES,
    SET_SUB_SERVICES,
    SET_VIEW_TYPE,
    START_AGE_GROUPS,
    START_ILLNESSES,
    START_SERVICES,
} from "../../constants/ActionTypes";
import axios from "../../services/api";
import { FETCH_START, FETCH_SUCCESS } from "../../constants/ActionTypes";
import {
    addAggregationToFilter, getCookie, setErrorMessage,
} from "../../utils/helpers";
import { buildElasticSearchQuery, setResourcesFilter } from "./Filters";
import { fetchError } from "./Common";
import axiosInstance from "../../services/api";
import {
    IS,
    RESOURCE_ELASTICSEARCH_INDEX, RESOURCE_FILTERS,
} from "../../constants/appConstants";
import { IMAGE } from "../../../../constants/serviceIcons";

/**
 * Start search
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
export const getIllnesses = () => {
    return (dispatch, getState) => {
        // dispatch(buildElasticSearchQuery());
        dispatch({ type: START_ILLNESSES });

        let urls = ["/api/search/related-field-model/?field=illnesses&document=Resource&page_size=100&page=1&label__istartswith="];

        const requests = urls.map((url) => axiosInstance.get(url));

        // eslint-disable-next-line no-undef
        Promise.all(requests)
            .then(([parents, childs, relation]) => {
                let illness = parents.data.results;
                const lang = getState().common.currentLanguage || "fr";
                illness.sort((a, b) => {
                    const labelA = a[`label_${ lang }`].toLowerCase();
                    const labelB = b[`label_${ lang }`].toLowerCase();

                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                });
                dispatch({ type: SET_ILLNESSES, payload: illness });
                dispatch(setFilterTermAggregation());
            })
            .catch((error) => {
                console.log(error);
                const message = setErrorMessage(error.status, error.message);

                dispatch({
                    type: ERROR_ILLNESSES, payload: message,
                });
            });
    };
};

export const getServices = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: START_SERVICES });

            const url = `/api/services/`;
            const response = await axiosInstance.get(url);
            let services = response.data;
            const lang = getState().common.currentLanguage || "fr";


            // Create a list of requests for sub-services and related fields in parallel
            const subServiceRequests = services
                .filter(s => !s.sub_services.length)
                .map(s => axiosInstance.get(`/api/search/related-field-model/?field=${ s.name }&document=Resource&page_size=100&page=1`));

            // Await all requests for sub-services
            // eslint-disable-next-line no-undef
            const subServicesResponses = await Promise.all(subServiceRequests);

            // Map services and process sub-services
            const servicesMapped = services.map((service, idx) => {
                service.id = idx;
                if (!service.sub_services.length) {
                    service.children = subServicesResponses.shift().data.results;
                } else {
                    service.abstracted = true;
                    service.children = service.sub_services.map(subService => ({
                        ...subService,
                        abstracted: true
                    }));
                }
                return service;
            });

            // Additional requests for sub-services that have nested sub-services
            const nestedSubServiceRequests = [];
            for (const service of servicesMapped) {
                if (service.sub_services.length) {
                    const urls = service.sub_services.filter(s => !s['is_boolean']).map(s => `/api/search/related-field-model/?field=${ s.name }&document=Resource&page_size=100&page=1`);
                    nestedSubServiceRequests.push(...urls.map(url => axiosInstance.get(url)));
                }
            }

            // eslint-disable-next-line no-undef
            const nestedSubServicesResponses = await Promise.all(nestedSubServiceRequests);
            // Assign values to nested sub-services
            let responseIndex = 0;
            for (const service of servicesMapped) {
                if (service.sub_services.length) {
                    if (service['is_boolean']) {
                        service.children = service.sub_services;
                    } else {
                        service.children = service.children.filter(s => !s['is_boolean']).map((child, idx) => {
                            child.value = nestedSubServicesResponses[responseIndex].data.results.map(res => res.id);
                            responseIndex++;
                            return child;
                        });
                    }

                }
            }
            servicesMapped.sort((a, b) => {
                const labelA = a[`label_${ lang }`].toLowerCase();
                const labelB = b[`label_${ lang }`].toLowerCase();

                if (labelA < labelB) {
                    return -1;
                }
                if (labelA > labelB) {
                    return 1;
                }
                return 0;
            });

            // sort children of each service
            servicesMapped.forEach(service => {
                service.sub_services.sort((a, b) => {
                    const labelA = a[`label_${ lang }`].toLowerCase();
                    const labelB = b[`label_${ lang }`].toLowerCase();

                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                });
                service.children.sort((a, b) => {
                    const labelA = a[`label_${ lang }`].toLowerCase();
                    const labelB = b[`label_${ lang }`].toLowerCase();

                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                });
            });
            dispatch({ type: SET_SERVICES, payload: servicesMapped });
            dispatch(setFilterTermAggregation());

            const selectedService = JSON.parse(localStorage.getItem("service"));
            const selectedSubService = JSON.parse(localStorage.getItem("subServices"));
            if (selectedService) {
                const serviceToSelect = servicesMapped.find(s => s.name === selectedService.name);
                if (serviceToSelect.abstracted) {
                    const esFields = serviceToSelect.children.filter(s => selectedSubService[s.name]).map(c => c.name);
                    const values = serviceToSelect.children.filter(s => selectedSubService[s.name]).map(c => c.value);
                    dispatch(setResourcesFilter(esFields, values, Array(esFields.length).fill("id"), IS));
                } else {
                    const esField = serviceToSelect.name;
                    const value = serviceToSelect.children.filter(s => selectedSubService[s.value]).map(c => c.id);
                    dispatch(setResourcesFilter(esField, value, "id", IS));
                }
            }
            // localStorage.removeItem("service");
            // localStorage.removeItem("subServices");


        } catch (error) {
            const message = setErrorMessage(error.status, error.message);
            dispatch({ type: ERROR_SERVICES, payload: message });
        }
    };
};


export const getAgeGroup = () => {
    return (dispatch) => {
        // dispatch(buildElasticSearchQuery());
        dispatch({ type: START_AGE_GROUPS });

        let url = "/api/search/related-field-model/?field=age_groups&document=Resource&page_size=100&page=1&label__istartswith=";

        // eslint-disable-next-line no-undef
        axiosInstance
            .get(url)
            .then(({ data }) => {
                let ageGroups = data.results;
                // eslint-disable-next-line no-debugger
                const order = ["kid", "adult", "old"];
                ageGroups.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value));
                dispatch({ type: SET_AGE_GROUPS, payload: ageGroups });
                dispatch(setFilterTermAggregation());
            })
            .catch((error) => {
                const message = setErrorMessage(error.status, error.message);
                dispatch({
                    type: ERROR_AGE_GROUPS, payload: message,
                });
            });
    };
};

export const setResultViewtype = (viewType) => {
    return (dispatch) => {
        dispatch({
            type: SET_VIEW_TYPE, payload: viewType,
        });
    };
};

export const setFilterTermAggregation = () => {
    return (dispatch, getState) => {
        const aggregations = getState().search.results.aggregations?.[0]?.[RESOURCE_ELASTICSEARCH_INDEX];
        const filters = getState().checkboxes;
        const servicesCheckboxes = filters.services;
        const serviceAgg = aggregations?.services;
        const subServiceAgg = aggregations?.sub_services;
        for (let idx in servicesCheckboxes) {
            if (servicesCheckboxes[idx]['is_boolean']) {
                servicesCheckboxes[idx].children = servicesCheckboxes[idx].children.map(c => ({
                    ...c,
                    count: subServiceAgg ? (subServiceAgg.filter(ss => c['agg_name'] === ss.id)[0]?.count || 0) : 0
                }));
            } else if (servicesCheckboxes[idx].abstracted) {
                servicesCheckboxes[idx].children = servicesCheckboxes[idx].children.map(c => ({
                    ...c,
                    count: subServiceAgg ? (subServiceAgg.filter(ss => c.name.startsWith(ss.id))[0]?.count || 0) : 0
                }));
            } else {
                servicesCheckboxes[idx].children = servicesCheckboxes[idx].children.map(c => {
                    const count = subServiceAgg ? (subServiceAgg.filter(a => c.value.startsWith(a.id))[0]?.count || 0) : 0;
                    return ({
                        ...c,
                        count
                    });
                });
            }
            servicesCheckboxes[idx].count = serviceAgg ? (serviceAgg.filter(a => servicesCheckboxes[idx].name.startsWith(a.id))[0]?.count || 0) : 0;
        }
        const ageGroupsCheckboxes = addAggregationToFilter(filters.age_groups, [aggregations?.age_groups || []]);
        const illnessesCheckboxes = addAggregationToFilter(filters.illnesses, [aggregations?.illnesses || []]);
        dispatch({
            type: SET_FILTER_TERM_AGGREGATIONS, servicesCheckboxes, // coreSerivcesCheckboxes,
            // subServicesCheckboxes,
            ageGroupsCheckboxes, illnessesCheckboxes,
        });
    };
};
