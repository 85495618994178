import {
    SET_NEWS_FILTERS,
    SET_DOCUMENT_FILTERS,
    SET_GLOBAL_SEARCH_FILTERS,
    SET_RESOURCE_FILTERS,
    BUILD_ELASTIC_SEARCH_QUERY,
    SET_ACTIVE_TAB,
    SET_SEARCH_QUERY,
    SET_PAGE,
    SET_SORT_BY,
    RESET_FILTERS,
    SET_RESOURCE_LOCATION, RESET_RESOURCE_LOCATION,
} from "../../constants/ActionTypes";
import {
    DOCUMENTS_ELASTICSEARCH_DOCUMENT,
    GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
    IS_PUBLISHED_FILTER,
    NEWS_ELASTICSEARCH_DOCUMENT, ORDER_BY_RELEVANCE,
    RESOURCE_ELASTICSEARCH_DOCUMENT,
} from "../../constants/appConstants";
import { debounce } from "../../utils/helpers";
import { saveRecentSearches } from "./Autocomplete";
import { startSearch } from "./Search";

/**
 * Generic Fields fetch
 *
 * @param {*} actionType
 * @param {*} fieldDocument
 * @return {*}
 */
const setFilterValue = (
    actionType,
    document,
    field,
    data,
    columnName,
    operator
) => {
    return (dispatch) => {
        if (Array.isArray(field)) {
            // Handle the case where fields or data are not arrays
            for (let i = 0; i < field.length; i++) {
                dispatch({
                    type: actionType,
                    document: document,
                    payload: data[i],
                    field: field[i],
                    columnName: columnName[i],
                    operator: operator,
                });
            }
        } else {
            dispatch({
                type: actionType,
                document: document,
                payload: data,
                field: field,
                columnName: columnName,
                operator: operator,
            });
        }

        dispatch(setPage(1));
        dispatch(buildElasticSearchQuery());
        debouncedStartSearchOnly(dispatch);

    };
};

/**
 * Set filters for news Document
 *
 * @returns
 */
export const setNewsFilter = (field, data, columnName, operator) => {
    return setFilterValue(
        SET_NEWS_FILTERS,
        NEWS_ELASTICSEARCH_DOCUMENT,
        field,
        data,
        columnName,
        operator
    );
};

/**
 * Set filters for document
 *
 * @returns
 */
export const setDocumentFilter = (field, data, columnName, operator) => {
    return setFilterValue(
        SET_DOCUMENT_FILTERS,
        DOCUMENTS_ELASTICSEARCH_DOCUMENT,
        field,
        data,
        columnName,
        operator
    );
};

/**
 * set Global search filters
 *
 * @returns
 */
export const setGlobalSearchFilter = (field, data, columnName, operator) => {
    return setFilterValue(
        SET_GLOBAL_SEARCH_FILTERS,
        GLOBAL_SEARCH_ELASTICSEARCH_DOCUMENT,
        field,
        data,
        columnName,
        operator
    );
};

export const setResourcesFilter = (field, data, columnName, operator) => {
    return setFilterValue(
        SET_RESOURCE_FILTERS,
        RESOURCE_ELASTICSEARCH_DOCUMENT,
        field,
        data,
        columnName,
        operator
    );
};

/**
 * Set location for resource search
 * we use this for sequentianl fallback search
 *
 * @param {*} field
 * @param {*} data
 * @param {*} columnName
 * @param {*} operator
 * @returns
 */
export const setResourceLocation = (field) => {
    return (dispatch) => {
        dispatch({
            type: SET_RESOURCE_LOCATION,
            document: RESOURCE_ELASTICSEARCH_DOCUMENT,
            field: field,
        });
        dispatch(setPage(1));
        dispatch(buildElasticSearchQuery());
        debouncedStartSearchOnly(dispatch);
    };
};
export const resetResourceLocation = (field) => {
    return (dispatch) => {
        dispatch({
            type: RESET_RESOURCE_LOCATION,
            document: RESOURCE_ELASTICSEARCH_DOCUMENT,
            field: field,
        });
        dispatch({
            type: SET_SORT_BY,
            payload: ORDER_BY_RELEVANCE
        });
        dispatch(setPage(1));
        dispatch(buildElasticSearchQuery());
        debouncedStartSearchOnly(dispatch);
    };
};

/**
 * Build elasticsearch query
 *
 * @returns
 */
export const buildElasticSearchQuery = () => {
    return (dispatch, getState) => {
        dispatch({
            type: BUILD_ELASTIC_SEARCH_QUERY,
            currentlanguage: getState().common.currentLanguage,
            defaultFilter: IS_PUBLISHED_FILTER,
        });
    };
};

/**
 * Debounce for search query
 */
export const debouncedStartSearchOnly = debounce((dispatch, callback = () => {
}) => {
    dispatch(startSearch(callback));
}, 100);

const debouncedStartSearch = debounce((dispatch, callback = () => {
}) => {
    dispatch(setPage(1));
    dispatch(startSearch(callback));
}, 600);

export const setSearchQuery = (value, callback = () => {
}) => {
    return (dispatch, getState) => {
        const state = getState();

        // Use value from state.autoComplete.query if value is null
        const searchQuery = value !== null ? value : state.autoComplete.query;
        dispatch({
            type: SET_SEARCH_QUERY,
            payload: searchQuery,
        });
        callback();
        dispatch(setPage(1));
        dispatch(saveRecentSearches(searchQuery));
    };
};

export const setSortCoordinates = (coordinates) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_BY,
            payload: {
                _geo_distance: {
                    coordinates: coordinates,
                    order: "asc",
                    unit: "km",
                }
            },
        });
    };
};

export const setSorting = (sorting) => {
    return (dispatch) => {
        dispatch({
            type: SET_SORT_BY,
            payload: sorting,
        });
        debouncedStartSearchOnly(dispatch);
    };
};

/**
 * Set active filters tab
 *
 * @param {*} tab
 * @returns
 */
export const setActiveTab = (tab) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_TAB,
            payload: tab,
        });
        debouncedStartSearchOnly(dispatch);
    };
};

export const setPage = (page) => {
    return (dispatch) => {
        dispatch({
            type: SET_PAGE,
            payload: page,
        });
        debouncedStartSearchOnly(dispatch);
    };
};

export const resetFilters = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FILTERS,
        });
        dispatch(setPage(1));
        dispatch(buildElasticSearchQuery());
        debouncedStartSearchOnly(dispatch);
    };
};
